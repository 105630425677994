import GridPlugin from '@aspectus/vue-grid'
import * as Tabs from 'vue-slim-tabs'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import VueSocialSharing from 'vue-social-sharing'
import { ObserveVisibility } from 'vue-observe-visibility'
import StarRating from 'vue-star-rating'

import Actions from './Actions'
import bem from './bem'
import Catalogs from './Catalogs'
import Controls from './Controls'
import Search from './Search'
import UiElements from './UiElements'
import Forms from './Forms'
import Cart from './Cart'
import Modals from './Modals'
import Order from './Order'
import Cabinet from './Cabinet'
import Reviews from './Reviews'

export default function plugin(Vue) {
  Vue.use(Tabs)
  Vue.use(GridPlugin)
  Vue.use(VueSocialSharing)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      height: 'auto',
      clickToClose: true,
      scrollable: false,
      transition: 'fade',
      overlayTransition: 'fade',
    },
  })
  Vue.use(Actions)
  Vue.use(bem)
  Vue.use(Catalogs)
  Vue.use(Controls)
  Vue.use(Search)
  Vue.use(UiElements)
  Vue.use(Forms)
  Vue.use(Modals)
  Vue.use(Order)
  Vue.use(Cart)
  Vue.use(Cabinet)
  Vue.use(Reviews)
  Vue.directive('observe-visibility', ObserveVisibility)
  Vue.component('star-rating', StarRating)
}
