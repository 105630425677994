<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_3sm(
      tag="p"
      weight="bold"
      size="2md"
      height="xs"
    ) {{_('Подписка на новости')}}
    bem-text.ma--bottom_lg(
      tag="p"
      size="2xs"
      height="xs"
    ) {{_('Вы будете получать подборку полезной информации по интересующей вас теме')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      news-subscription-form-controller(
        :formdata="formdata"
        @handle-response="handleSubmit($event)"
      )
        template(#default="{ categoriesList, send, isLoad, handleSelection, checkCurrentOption }")
          div
            +b.form.ma--bottom_sm
              control-input(
                rules="custom_email|required"
                type="email"
                label-class="form__label"
                class-name="form__input"
                :input-label="_('E-mail')"
                v-model="formdata.email"
                :required="true"
                name="email"
              )
            +b.common-multiselect--fullwidth.form.ma--bottom_sm-md
              control-multiselect(
                :options="categoriesList"
                track-by="id"
                label="title"
                :allow-empty="false"
                label-class="form__label"
                :input-label="_('Тема')"
                v-model="formdata.category"
                :placeholder="_('Выберите тему')"
                :required="false"
                :multiple="true"
                @input="handleSelection($event)"
                @select="checkCurrentOption($event)"
                name="category"
              )
                template(#noResult)
                  span {{ _("Елементи не знайдено") }}
                template(#noOptions)
                  span {{ _("Список порожній") }}
            bem-button.de-text--size_2xs(
              appearance="fill-accent-1"
              space="sm"
              width="full"
              rounded="sm"
              :disable="isLoad"
              @event="validateBeforeSubmit(send)"
            ) {{_('Подписаться321')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'

export default {
  name: 'news-subscription-form-modal',
  mixins: [FormMixin],
  components: {
    'news-subscription-form-controller': () => import('./NewsSubscriptionController.vue'),
  },
  data() {
    return {
      formdata: {
        email: '',
        category: null,
      },
    }
  },
  methods: {
    handleSubmit(res) {
      this.handleResponse({ modal: true, res })
    },
  },
}
</script>
