<template lang="pug">
  include ../mixins
  div.position--relative
    ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--light')
    row.ma--bottom_md(v-if="!isResponse" space="sm-xl" ref="card")
      slot(name="cards" ref="card")
    renderer(
      v-if="isResponse"
      :result="response"
      class-render='g-row g-row--space_sm-xxl'
    )
    row.ma--top_md(justify="center")
      cell(cols="12 9-lg 8-xxl")
        pagination(
          v-if="count > perPage"
          class-wrapper="pagination__wrapper--light"
          :is-scroll="false"
          :current='page'
          :total='count'
          :perPage='perPage'
          :is-add-more="isCombinePagination"
          @add="isAddPage = $event"
          @page-changed="page = $event; getCards($event)"
        )
</template>

<script>
import {
  articlesListResource,
  promoListResource,
} from '@services/common.service.js'
import { prefixLanguage } from '@helpers/url'

export default {
  name: 'request-component',
  components: {
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
    pagination: () => import('@app/UiElements/Pagination'),
  },
  props: {
    slug: String,
    resource: String,
    pagination: Object,
    category: {
      type: String,
      required: false,
    },
    isCombinePagination: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      sendData: {},
      response: '',
      resourceObject: {
        articles: articlesListResource,
        stock: promoListResource,
      },
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
      isAddPage: false,
      isFirstAdd: true,
      firstCards: '',
      isLoad: false,
      isResponse: false,
    }
  },
  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },
  mounted() {
    if (true === this.isCombinePagination) {
      this.getFirstCards()
    }
  },
  methods: {
    getCards() {
      this.isLoad = true
      return this.resourceObject[this.resource].execute({
        page: this.page,
        category: this.category,
      })
        .then(res => {
          this.handleResponse(res)
        })
    },
    handleResponse({ data, meta }) {
      if (true === this.isCombinePagination) {
        if (true === this.isAddPage) {
          if (true === this.isFirstAdd) this.response = this.firstCards
          this.response += data
          this.isFirstAdd = false
        } else {
          this.isFirstAdd = false
          this.response = data
          this.firstCards = ''
        }
      } else this.response = data
      this.page = meta.page
      this.count = meta.count
      this.perPage = meta.perPage
      this.pushHistory()
      this.isResponse = true
      this.isLoad = false
    },
    getFirstCards() {
      this.firstCards = this.$refs.card.innerHTML
    },
    pushHistory() {
      let path = ''
      if (1 < this.page) path = `${prefixLanguage()}/${this.slug}${`${this.category }/` || ''}page/${this.page}/`
      else path = `${prefixLanguage()}/${this.slug}`
      window.history.pushState(null, null, path)
    },
  },
}
</script>
