import { disableAnimation } from './disable-animation'
import { mobileMenuToggler } from './sidebar'
import { toggleCategoryMenu } from './category-menu'
import { toggleSeoText } from './seo-text'
import { wysiwygHelper } from './wysiwyg-helper'
import { initGrid } from './gallery'
import { handleHoveredMenu } from './hovered-menu'
// import { fixHeader } from './fixed-header'
import { setCardHeight } from './card-height'
import { sliderSnap } from './slider-snap'

document.addEventListener('DOMContentLoaded', () => {
  disableAnimation()
  mobileMenuToggler()
  toggleCategoryMenu()
  toggleSeoText()
  wysiwygHelper()
  initGrid()
  handleHoveredMenu()
  // fixHeader()
  setCardHeight()
  sliderSnap()
}, { passive: true })
